<template>
  <div :class="icon ? 'stand' : 'stand x_default'">
    <p class="stand_iconview">
      <img :src="icon" alt="" class="stand_icon">
    </p>
    <div class="stand_text">
      <span class="stand_name">{{name}}</span>
      <div>
        <span class="stand_type">{{type.join(' ')}}</span>
        <span class="stand_tip" :style="'color:' + color + ';border: 1px solid ' + color">{{tip}}</span>
      </div>
      <span class="stand_des">{{des}}</span>
    </div>
  </div>
</template>
<script>
export default {
  name: "Stand",
  // 
  props: ["name",'type','des','icon', 'tip'],
  data() {
    return {
      color: ''
    };
  },
  mounted() {
    var timer = setInterval(() => {
      if(this.tip != "占位") {
        switch (this.tip) {
          case "传奇":
            this.color = "#f21919";
            break;
          case "仙侠":
            this.color = "#64c60d";
            break;
          case "魔幻":
            this.color = "#8138c0";
            break;
          case "三国":
            this.color = "#f29d23";
            break;
          default:
            break;
        }
        clearInterval(timer)
      }
    }, 50)

  },
  methods: {
    
  },
};
</script>
<style lang="stylus" scoped>
@import './common.styl';
.stand
  margin 0 auto
  width $viewWidth
  display flex
  align-items center
.stand_text
  display: flex;
  flex-direction: column;
  align-items: flex-start;
.stand_iconview
  height 56px
  width 56px
  border-radius 12px
  margin-right 10px
.stand_icon
  height 100%
  width 100%
  border-radius 12px
.stand_name
  font-size 18px
  font-weight 600
  width 70vw
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
.stand_type, .stand_des
  margin-top 2px
  font-size 12px
  color $themeColor
.stand_des
  width 74vw
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
.stand_tip
  color: #f21919;
  font-size: 12px;
  border: 1px solid #f21919;
  border-radius: 4px;
  height: 16px;
  line-height 16px
  padding: 0 4px;
  margin-left 6px
</style>
<template>
  <div class="container" style="font-size: 0;">
    <div class="find_tab" ref="findTab">
      <div class="find_tab_select">
        <div class="find_tab_item" ref="findItem1" @click="goHot">人气热度</div>
        <div class="find_tab_item" ref="findItem2" @click="goNew">新游上榜</div>
        <div
          class="find_tab_line"
          :style="'transform: translate3d( ' + pos + 'px, 0, 0);'"
        ></div>
      </div>
    </div>
    <van-tree-select
      :items="items"
      :main-active-index.sync="active"
      @click-nav="clickNav"
      :height="selectHeight"
      class="find_tree_select"
      ref="findTree"
    >
      <template #content>
        <van-list
          v-model="loading"
          :finished="finished"
          :error.sync="error"
          finished-text="- - - 我可是有底线的 - - -"
          error-text="请求失败，点击重新加载"
          @load="onLoad"
        >
          <Strip
            v-for="item in lists"
            class="find_item"
            :key="item.id"
            :item="item"
            route="/page"
            routeios="/pageios"
          ></Strip>
        </van-list>
      </template>
    </van-tree-select>
  </div>
</template>
<script>
import Hot from "@/components/Hot.vue";
import Stand from "@/components/Stand.vue";
import Strip from "@/components/Strip.vue";

import { askHotlist, askNewlist, askTypelist } from '@/api'

export default {
  name: "Find",
  components: {
    Hot,
    Stand,
    Strip,
  },
  data() {
    return {
      error: false,
      loading: false,
      finished: false,
      pos: 32,
      active: 0,
      selectHeight: document.body.offsetHeight - 90,
      items: [],
      lists: [
        {
          id: null,
          name: "占位占位占位",
          type: ["占位", "占位"],
          icon: null,
          des: "占位占位占位占位占位占位占位占位",
          tip: "占位",
        },
        {
          id: null,
          name: "占位占位占位",
          type: ["占位", "占位"],
          icon: null,
          des: "占位占位占位占位占位占位占位占位",
          tip: "占位",
        },
        {
          id: null,
          name: "占位占位占位",
          type: ["占位", "占位"],
          icon: null,
          des: "占位占位占位占位占位占位占位占位",
          tip: "占位",
        },
        {
          id: null,
          name: "占位占位占位",
          type: ["占位", "占位"],
          icon: null,
          des: "占位占位占位占位占位占位占位占位",
          tip: "占位",
        },
        {
          id: null,
          name: "占位占位占位",
          type: ["占位", "占位"],
          icon: null,
          des: "占位占位占位占位占位占位占位占位",
          tip: "占位",
        },
        {
          id: null,
          name: "占位占位占位",
          type: ["占位", "占位"],
          icon: null,
          des: "占位占位占位占位占位占位占位占位",
          tip: "占位",
        },
        {
          id: null,
          name: "占位占位占位",
          type: ["占位", "占位"],
          icon: null,
          des: "占位占位占位占位占位占位占位占位",
          tip: "占位",
        },
        {
          id: null,
          name: "占位占位占位",
          type: ["占位", "占位"],
          icon: null,
          des: "占位占位占位占位占位占位占位占位",
          tip: "占位",
        }
      ],
      handleHotLists: [],
      handleNewLists: [],
      typeNums: [],
      typeLists: [],
      clist: {
        hot: 1,
        new: 0
      },
      currentNav: 0,
      page: 0
    };
  },
  mounted() {
    if(judgeBigScreen()) {
      this.$refs.findTab.className = "find_tab findTabAndroid"
      this.$refs.findTree.className = "find_tree_select van-tree-select findSelectAndroid"
      this.$refs.findItem1.className = "find_tab_item findItemAndroid"
      this.$refs.findItem2.className = "find_tab_item findItemAndroid"
    }
    askTypelist().then(res => {
      if(res.code === 1) {
        var data = []
        res.data.map(e => {
          data.push({
            id: e.id,
            text: e.name
          })
        })
        data.unshift({ id: 0, text: '全部' })
        this.items = data
      }
    })
    // this.getHotData(0, 0)
    // var timer = setTimeout(() => {
    //   // this.lists = [
    //   //   {
    //   //     id: 1,
    //   //     name: "多兰大陆-送绝版坐骑",
    //   //     type: ["卡牌", "二次元"],
    //   //     icon: gameIcon,
    //   //     des: "冒险没有停歇, 全新的放置格斗游戏冒险冒险没有停歇",
    //   //     tip: "传奇",
    //   //     discount: "5.0折",
    //   //   },
    //   // ];
    //   clearTimeout(timer);
    // }, 800);
  },
  methods: {
    onLoad() {
      if(this.clist.hot === 1) {
        this.getHotData(this.currentNav, this.page)
      }
      if(this.clist.new === 1) {
        this.getNewData(this.currentNav, this.page)
      }
    },
    getHotData(type, page) {
      askHotlist({
        typeId: type,
        page: page
      }).then(res => {
        this.page++
        if(res.code === 1) {
          res.data.map(e => {
            switch (e.type_name) {
              case "传奇":
                e.color = "#f21919"
                break;
              case "仙侠":
                e.color = "#64c60d"
                break;
              case "魔幻":
                e.color = "#8138c0"
                break;
              case "三国":
                e.color = "#f29d23"
                break;
              default:
                break;
            }
            if(this.currentNav > 0) {
              if(e.type_id == this.currentNav) {
                this.handleHotLists.push({
                  id: e.id,
                  name: e.name,
                  type: e.play_name,
                  icon: e.icon,
                  des: e.introduction,
                  tip: e.type_name,
                  type_id: e.type_id,
                  color: e.color
                })
              }
            } else {
              this.handleHotLists.push({
                id: e.id,
                name: e.name,
                type: e.play_name,
                icon: e.icon,
                des: e.introduction,
                tip: e.type_name,
                type_id: e.type_id,
                color: e.color
              })
            }
            let deWeightThree = () => {
                let map = new Map();
                for (let item of this.handleHotLists) {
                    if (!map.has(item.id)) {
                        map.set(item.id, item);
                    }
                }
                return [...map.values()];
            }
            let newHotLists = deWeightThree();
            this.lists = newHotLists
          })
          this.loading = false
        } else {
          this.loading = true
          this.finished = true
        }
      })
    },
    getNewData(type, page) {
      askNewlist({
        typeId: type,
        page: page
      }).then(res => {
        this.page++
        if(res.code === 1) {
          res.data.map(e => {
            switch (e.type_name) {
              case "传奇":
                e.color = "#f21919"
                break;
              case "仙侠":
                e.color = "#64c60d"
                break;
              case "魔幻":
                e.color = "#8138c0"
                break;
              case "三国":
                e.color = "#f29d23"
                break;
              default:
                break;
            }
            if(this.currentNav > 0) {
              if(e.type_id == this.currentNav) {
                this.handleNewLists.push({
                  id: e.id,
                  name: e.name,
                  type: e.play_name,
                  icon: e.icon,
                  des: e.introduction,
                  tip: e.type_name,
                  type_id: e.type_id,
                  color: e.color
                })
              }
            } else {
              this.handleNewLists.push({
                id: e.id,
                name: e.name,
                type: e.play_name,
                icon: e.icon,
                des: e.introduction,
                tip: e.type_name,
                type_id: e.type_id,
                color: e.color
              })
            }
            let deWeightThree = () => {
              let map = new Map();
              for (let item of this.handleNewLists) {
                if (!map.has(item.id)) {
                  map.set(item.id, item);
                }
              }
              return [...map.values()];
            }
            let newNewLists = deWeightThree();
            this.lists = newNewLists
          })
          this.loading = false
        } else {
          this.loading = true
          this.finished = true
        }
      })
    },
    goHot() {
      this.handleHotLists = []
      this.lists = this.$options.data().lists
      this.clist.hot = 1
      this.clist.new = 0
      this.pos = 32
      this.page = 0
      this.loading = true
      this.finished = false
      this.getHotData(this.currentNav, this.page)
    },
    goNew() {
      this.handleNewLists = []
      this.lists = this.$options.data().lists
      this.clist.hot = 0
      this.clist.new = 1
      this.pos = 122
      this.page = 0
      this.loading = true
      this.finished = false
      this.getNewData(this.currentNav, this.page)
    },
    clickNav(e) {
      e = this.items[e].id
      this.currentNav = e;
      this.handleNewLists = []
      this.handleHotLists = []
      this.lists = this.$options.data().lists
      this.page = 0
      this.loading = true
      this.finished = false
      if(e === 0) {
        if(this.clist.hot === 1) {
          this.getHotData(e, 0)
        }
        if(this.clist.new === 1) {
          this.getNewData(e, 0)
        }
      } else {
        if(this.clist.hot === 1) {
          this.getHotData(e, this.page)
        }
        if(this.clist.new === 1) {
          this.getNewData(e, this.page)
        }
      }
    },
  },
};
</script>
<style scoped lang="stylus">
@import '../components/common.styl';

.find_tab {
  background: url('../assets/playedt.png') no-repeat;
  background-size: 100%;
  width: 100%;
  height: calc(50px + constant(safe-area-inset-bottom));
  height: calc(50px + env(safe-area-inset-bottom));
  position: fixed;
  z-index: 1;
}

.find_tab_select {
  width: 180px;
  height: 100%;
  line-height: 30px;
  display: flex;
  font-size: 16px;
  color: #fff;
  position: absolute;
  right: 74px;
  bottom: 0;
  align-items: center;
}

.find_tab_item {
  width: 90px;
  text-align: center;
  margin-top: calc(16px + constant(safe-area-inset-bottom));
  margin-top: calc(16px + env(safe-area-inset-bottom));
}

.find_tab_line {
  width: 26px;
  height: 2px;
  background: $themeWhite;
  position: absolute;
  bottom: 2px;
  left: 0;
  transition: all 0.3s ease;
  border-radius: 2px;
}

.find_tree_select {
  margin-top: calc(50px + constant(safe-area-inset-bottom));
  margin-top: calc(50px + env(safe-area-inset-bottom));
}

.find_item {
  margin: 20px 0;
  justify-content: center;
}

.find_item:last-child {
  margin-bottom: 100px;
}
</style>